.ContactMain {
    display: flex;
    flex-direction: column;
    width: 100vw;
    background-color: rgb(68, 71, 78);
    color: white;
    text-align: left;
    font-size: 4vw;
}
 
a {
    text-decoration: none;
    color: white;
}
 
.ContactInner {
    margin: 10px 0px 10px 20px;
}
 
#FBLogo {
    width: 8vh;
    margin-left: 9vw;
}
 
@media screen and (min-width: 800px){
    .ContactMain {
        flex-direction: row;
        padding: 40px;
        height: 50vh;
        font-size: 15px;
    }
 
    #FBLogo {
        width: 8vh;
        margin-left: 1vw;
    }
 
    .ContactInner{
        margin-left: 80px;
    }
}
