.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  color: white;
  background: rgb(30,38,45);
  background: linear-gradient(135deg, rgba(30,38,45,1) 0%, rgba(59,63,83,1) 31%, rgba(79,66,93,1) 68%, rgba(49,42,56,1) 100%);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

