
.HomePageMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
}
 
@media screen and (min-width: 600px){
    #HomeCarousel {
        width: 60vw;
    }
}
 
@media screen and (min-width: 950px){
 
    .HomePageMain {
        align-items: center;
    }   
 
    #HomeCarousel {
        width: 50vw;
    }
    
}
