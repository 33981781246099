.VideosMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}
 
.VideosMain img {
    width: 2vh;
    height: 2vh;
    margin: none;
}
 
.VideoLabel {
    text-align: left;
}
 
.VideosLinkBack {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 1.5vh;
}
 
iframe {
    padding: 10vh;
    width: 60vw;
}
 
@media screen and (min-width: 925px){
    .VideosLinkBack {
        font-size: 2vh;
    }
    video {
        width: 60vw;
    }
}
